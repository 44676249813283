
function ProductsCategoryPage() {
    return (
        <div className="App">
           <h1>HOME PAGE</h1>
        </div>
    );
}

export default ProductsCategoryPage;
